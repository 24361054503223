import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authenticate } from '../../store/session';
import { useNavigate } from 'react-router-dom'

import CreateMessageForm from './CreateMessageForm';
import ProviderList from './ProviderList';
import './AdminPortal.css'

function AdminPortal(){
  const [currentUserIsLoaded, setCurrentUserIsLoaded] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(state => state.session.user);

  useEffect(() => {
    (async () => {
      await dispatch(authenticate())
      setCurrentUserIsLoaded(true);
    })();
  }, [dispatch])
  if (!currentUser){
    navigate('/adminlogin')
  }
  if (currentUserIsLoaded === false) return null

  return (
    <div className='adminportal-container'>
      <h1 style={{color: "var(--black)", fontWeight: 400, fontSize: "36px", paddingTop: "40px"}}>Admin Portal for Allergy & Asthma of Central Maryland</h1>
      <div className='divider'></div>
      <h3 className='edit-headers'>Edit Messages</h3>

      <div className='divider'></div>

      <CreateMessageForm />
      <div className='divider'></div>

      <h3 className='edit-headers'>Edit Providers</h3>
      <div className='divider'></div>

      <ProviderList />
    </div>
  )

}
export default AdminPortal