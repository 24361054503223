import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStaff } from '../../store/practice';
import './OurPractice.css'

function OurPractice() {
  const dispatch = useDispatch()
  const allStaff = useSelector(state => state.staff)
  const [loaded, setLoaded] = useState(false)

  let staffArr;

  useEffect(() => {
    dispatch(getAllStaff())
  }, [dispatch])

  useEffect(() => {
    if (staffArr){
      setLoaded(true)
    }
  }, [staffArr])

  if (allStaff) {
    staffArr = Object.values(allStaff)
  }
  function image(name) {
    if (name === "Goldman") {
      return ("/static/images/MGoldman.jpg")
    } else if (name === "Korff") {
      return ("/static/images/MKorff.jpg")
    } else if (name === "Neuman-Sunshine") {
      return ("/static/images/DNeuman-Sunshine.jpg")
    } else if (name === "Uyehara") {
      return ("/static/images/AUyehara1.jpg")
    } else if (name === "Wettengel") {
      return ("/static/images/CMoughon.jpg")
    } else {
      return ("/static/images/stethoscope.jpeg")
    }
  }

  if (!staffArr){
    return null
  }
  function tagline(credential){
    if (credential === 'M.D.'){
      return "Physician"
    } else if (credential.includes('N.P')){
      return "Nurse Practitioner"
    }
  }

  return loaded && staffArr ? (
    <div className='practice-container'>
      {/* <h1>Our Practice</h1> */}
      <div className='practice-cards-container'>
        {staffArr.filter(employee => employee.display).map(employee => {
          return (
            <div className='practice-card'>
              <div className='practice-image-container'>
                <img src={image(employee.lastName)} alt='provider' className='practice-image' />
              </div>
              <div className='practice-card-text'>
                <h3>{tagline(employee.credentials)}</h3>
                <h1 className='practice-names'>
                  {employee.firstName} {employee.lastName}, {employee.credentials}
                </h1>
                <p className='practice-biography-text'>{employee.biography}</p>
              </div>
            </div>
          )
        })}
      </div>
      
</div>
  ) : (
    <div>
      <h1>Loading...</h1>
    </div>
  )
}
export default OurPractice