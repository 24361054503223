/*
This file holds the session reducer. This reducer processes actions dispatched
to the store that relate to the user's session.
*/

/*
These are the types of actions. 
- Constant to prevent typos
- Should be unique to the reducer to prevent action passed to another reducer
*/

const GET_MESSAGES = 'message/GET_MESSAGES'
const GET_ONE_MESSAGE = 'message/GET_ONE_MESSAGE'
const CREATE_MESSAGE = 'message/CREATE_MESSAGE'
const UPDATE_MESSAGE = 'message/UPDATE_MESSAGE'
const DELETE_MESSAGE = 'message/DELETE_MESSAGE'
/*
Action Creators
- Functions that accept dynamically generated payloads
- Return a JavaScript Object that is considered the action
- These can be dispatched by thunk action creater or within a component
*/

const getMessage = (message) => {
  return {
    type: GET_MESSAGES,
    message
  }
}
const getOneMessage = (message) => {
  return {
    type: GET_ONE_MESSAGE,
    message
  }
}
const createMessage = (message) => {
  return {
    type: CREATE_MESSAGE,
    message
  }
}
const updateMessage = (message) => {
  return {
    type: UPDATE_MESSAGE,
    message
  }
}
const deleteMessage = (messageId) => {
  return {
    type: DELETE_MESSAGE,
    messageId
  }
}


/*
Thunk Action Creators
- Function that returns a thunk function
- Thunk function is a function that is invoked by thunk middleware and gets passed
  the dispatch and getState store methods
- Asynchronous calls are made here, once complete a regular POJO action may be dispatched
*/
// Thunk Actions

// Get all of the messages
export const getAllMessage = () => async (dispatch) => {
  const response = await fetch('/api/messages/all')
  if (response.ok) {
    const messages = await response.json()
    dispatch(getMessage(messages))
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}

// Get one message
export const getAMessage = (id) => async (dispatch) => {
  const response = await fetch(`/api/messages/${id}`)
  if (response.ok) {
    const message = await response.json()
    dispatch(getOneMessage(message))
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}

// Creates a new Message
export const createAMessage = (payload) => async (dispatch) => {
  const response = await fetch(`/api/messages`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  });
  if (response.ok) {
    const message = await response.json()
    dispatch(createMessage(message))
    return message
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}

// Updates a Message Member
export const updateAMessage = (payload) => async (dispatch) => {
  const response = await fetch(`/api/messages/${payload.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  });
  if (response.ok) {
    const staff = await response.json()
    dispatch(updateMessage(staff))
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}



// Delete a Message Member
export const deleteAMessage = (id) => async (dispatch) => {
  const response = await fetch(`/api/messages/${id}`, {
    method: 'DELETE'
  });
  if (response.ok) {
    dispatch(deleteMessage(id))
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}


// Reducer
const initialState = {}

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      const allMessage = {}
      action.message.messages.forEach(message => {
        allMessage[message.id] = message
      })
      return { ...allMessage }
    case GET_ONE_MESSAGE:
      let oneMessage = { ...state }
      oneMessage[action.message.id] = action.message
      return oneMessage
    case CREATE_MESSAGE:
      return { ...state, [action.message.id]: action.message }
    case UPDATE_MESSAGE:
      return { ...state, [action.message.id]: action.message }
    case DELETE_MESSAGE:
      const deleteState = { ...state }
      delete deleteState[action.id]
      return deleteState
    default:
      return state;
  }
}


export default messageReducer