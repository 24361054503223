import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { updateAStaff, getAStaff, deleteAStaff } from '../../../store/practice';
import './EditProviderForm.css'

const EditProviderForm = ({ setShowModal, employee }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [errors, setErrors] = useState([])
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [firstName, setFirstName] = useState(employee.firstName)
  const [lastName, setLastName] = useState(employee.lastName)
  const [biography, setBiography] = useState(employee.biography)
  const [display, setDisplay] = useState(employee.display)
  const [order, setOrder] = useState(employee.order)


 

  const editProvider = async (e) => {
    e.preventDefault()
    setHasSubmitted(true)

    let payload = { id: employee.id, firstName: firstName, lastName: lastName, credentials: employee.credentials, biography:biography, display:display, imageUrl:employee.imageUrl, order:order }
    // if (!errors.length) {
    let data = await dispatch(updateAStaff(payload));
    if (Array.isArray(data)) {
      setErrors(data)
    } else {
      await dispatch(getAStaff(employee.id))
      await navigate('/adminportal')
      await setShowModal(false)
    }
    // }
  }

  function charRemaining(max, input) {
    return Number(max) - Number(input.length)
  }

  function check(expected, value){
    if (expected===value){
      return "checked"
    }
  }
  const isChecked = (value) => {
    return display === value;
  };

  return (
    <div className='provider-form-container'>
      <div
        className='cancel-button-container'
      >
        <i
          className="fa-solid fa-xmark fa-lg"
          onClick={() => setShowModal(false)}
        ></i>
      </div>
      <div className='provider-form-header-container'>
        <h2 className='provider-form-header'>Edit Provider {employee.name}</h2>
      </div>
      <form onSubmit={editProvider} className='provider-form-inner-container'>
        {hasSubmitted && errors.length > 0 && (<div className='error-container'>
          {errors.map((error, ind) => (
            <div key={ind} className='error-text'>{error.split(":")[1]}</div>
          ))}
        </div>)}
        <div className='provider-form-sections'>
          <label className='provider-form-labels'>First Name <p className='provider-form-required-text'>*</p></label>
          <input
            maxLength={100}
            type='text'
            name='First Name'
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            placeholder="First Name"
            className='provider-form-inputs'
          >
          </input>
          <p className='provider-form-char-remaining-text'>{charRemaining(100, firstName)} characters remaining</p>
          <label className='provider-form-labels'>Last Name <p className='provider-form-required-text'>*</p></label>
          <input
            maxLength={100}
            type='text'
            name='Last Name'
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            placeholder="Last Name"
            className='provider-form-inputs'
          >
          </input>
          <p className='provider-form-char-remaining-text'>{charRemaining(100, lastName)} characters remaining</p>
          <label className='provider-form-labels'>Biography <p className='provider-form-required-text'>*</p></label>
          <textarea
            maxLength={1000}
            type='text'
            name='Biography'
            onChange={(e) => setBiography(e.target.value)}
            value={biography}
            placeholder="Biography"
            className='provider-form-inputs'
            id='textarea'
          >
          </textarea>
          <p className='provider-form-char-remaining-text'>{charRemaining(1000, biography)} characters remaining</p>
          <div className='provider-radio-container'>
              <label className='provider-form-labels'>Display this provider?</label>
            <div className='provider-radio-dials'>
              <input
                type='radio'
                name='display'
                value='yes'
                onClick={(e) => setDisplay(true)}
                checked={isChecked(true)}
                className='provider-form-radio-text'
              >
              </input>
              <label className='provider-form-radio-text'>Yes</label>
              <input
                type='radio'
                name='display'
                value='no'
                onClick={(e) => setDisplay(false)}
                checked={isChecked(false)}
                className='provider-form-radio-text'
              >
              </input>
              <label className='provider-form-radio-text'>No</label>
            </div>
            

          </div>
          
{/* implement later */}
          {/* <label className='category-form-labels'>What number in the order? </label>
          <input
            type="number"
            name="order"
            value={order}
            onChange={(e) => setOrder(e.target.value)}
            >
          </input> */}
        </div>
        <div className='provider-form-button-container'>
          <button type='submit' className='provider-submit-button'>Submit</button>
        </div>
      </form>
    </div>
  )
}

export default EditProviderForm
