/*
This file holds the session reducer. This reducer processes actions dispatched
to the store that relate to the user's session.
*/

/*
These are the types of actions. 
- Constant to prevent typos
- Should be unique to the reducer to prevent action passed to another reducer
*/

const GET_STAFF = 'staff/GET_STAFF'
const GET_ONE_STAFF = 'staff/GET_ONE_STAFF'
const CREATE_STAFF = 'staff/CREATE_STAFF'
const UPDATE_STAFF = 'staff/UPDATE_STAFF'
const DELETE_STAFF = 'staff/DELETE_STAFF'
/*
Action Creators
- Functions that accept dynamically generated payloads
- Return a JavaScript Object that is considered the action
- These can be dispatched by thunk action creater or within a component
*/

const getStaff = (staff) => {
  return {
    type: GET_STAFF,
    staff
  }
}
const getStaffMember = (staff) => {
  return {
    type: GET_ONE_STAFF,
    staff
  }
}
const createStaff = (staff) => {
  return {
    type: CREATE_STAFF,
    staff
  }
}
const updateStaff = (staff) => {
  return {
    type: UPDATE_STAFF,
    staff
  }
}
const deleteStaff = (staffId) => {
  return {
    type: DELETE_STAFF,
    staffId
  }
}


/*
Thunk Action Creators
- Function that returns a thunk function
- Thunk function is a function that is invoked by thunk middleware and gets passed
  the dispatch and getState store methods
- Asynchronous calls are made here, once complete a regular POJO action may be dispatched
*/
// Thunk Actions

// Get all of the staff
export const getAllStaff = () => async (dispatch) => {
  const response = await fetch('/api/employees/all')
  if (response.ok) {
    const staff = await response.json()
    dispatch(getStaff(staff))
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}

// Get one Staff
export const getAStaff = (id) => async (dispatch) => {
  const response = await fetch(`/api/employees/${id}`)
  if (response.ok) {
    const staff = await response.json()
    dispatch(getStaffMember(staff))
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}

// Creates a new Staff
export const createAStaff = (payload) => async (dispatch) => {
  const response = await fetch(`/api/employees`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  });
  if (response.ok) {
    const staff = await response.json()
    dispatch(createStaff(staff))
    return staff
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}

// Updates a Staff Member
export const updateAStaff = (payload) => async (dispatch) => {
  const response = await fetch(`/api/employees/${payload.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  });
  if (response.ok) {
    const staff = await response.json()
    dispatch(updateStaff(staff))
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}



// Delete a Staff Member
export const deleteAStaff = (id) => async (dispatch) => {
  const response = await fetch(`/api/employees/${id}`, {
    method: 'DELETE'
  });
  if (response.ok) {
    dispatch(deleteStaff(id))
  } else if (response.status < 500) {
    const data = await response.json();
    if (data.errors) {
      return data.errors;
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}


// Reducer
const initialState = {}

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAFF:
      const allStaff = {}
      action.staff.employees.forEach(staff => {
        allStaff[staff.id] = staff
      })
      return { ...allStaff }
    case GET_ONE_STAFF:
      let oneStaff = { ...state }
      oneStaff[action.staff.id] = action.staff
      return oneStaff
    case CREATE_STAFF:
      return { ...state, [action.staff.id]: action.staff }
    case UPDATE_STAFF:
      return { ...state, [action.staff.id]: action.staff }
    case DELETE_STAFF:
      const deleteState = { ...state }
      delete deleteState[action.id]
      return deleteState
    default:
      return state;
  }
}


export default staffReducer