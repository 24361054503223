import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMessage } from '../../store/message';
import './Message.css'

function Message() {
  const dispatch = useDispatch()
  const allMessages = useSelector(state => state.message)
  const [loaded, setLoaded] = useState(false)

  let messageArr;
  let recent;
  let lines;
  useEffect(() => {
    dispatch(getAllMessage())
  }, [dispatch])

  useEffect(() => {
    setLoaded(true)
  }, [messageArr])

  if (allMessages) {
    messageArr = Object.values(allMessages)
    recent = messageArr[messageArr.length - 1]
    if (recent)(
      lines = recent.message.split('newline')
    )
  }


  // let toggle = document.querySelectorAll('.message-toggle-label')

  // Array.from(toggle).forEach(toggle => {
  //   toggle.addEventListener('keydown', e=> {
  //     if (e.which === 32 || e.which === 13){
  //       e.preventDefault()
  //       toggle.click();
  //     }
  //   })
  // })

  return loaded && lines ? (
    <div className="message-container">
      <input id='collapsible' className='message-toggle' type='checkbox'></input>
      <label for='collapsible' className='message-toggle-label'>Important Messages</label>
      <div className='message-collapsible-content'>
        <div className='message-content'>
          {lines.map((line, ind) => {
            return (
              <p key={ind}>
                {line}
              </p>
            )
          })}
          {/* <p>
            As of January 2022, our office is located at: <br />
            9891 Broken Land Pkwy, Suite 100 <br />
            Columbia, MD 21046 <br />
          </p> */}
        </div>
      </div>
    </div>
  ) : (
    <h1>Loading...</h1>
  )
}
export default Message