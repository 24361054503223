import './Forms.css'

function Forms() {

  return (
    <div className='forms-container'>
      {/* <h1>School Forms</h1> */}
      <p>
        <h1 className='tele-form-header'>School Forms</h1>

        School, daycare, and camp forms require the medical staff and the provider to review the patient's chart to ensure accurate information is communicated.
        <br />
        <br />
        Patients should complete as much demographic information as possible.  For patients with food allergies, please list out the food allergens that the patient is avoiding.
        <br />
        <br />
        Forms may be sent electronically via the <a href='https://10561.portal.athenahealth.com/' target="_blank">
         Patient Portal</a>, dropped off or mailed to the office.
        <br />
        <br />
        Standard turn-around time is <strong>2 weeks</strong> for <strong>$30</strong>.
        <br />
        Expedited turn-around time is <strong>2 business days</strong> for <strong>$40</strong>.
        <br />
        <br />

        <strong>Mailing address:</strong>         <br />
        Allergy & Asthma of Central Maryland         <br />
        9891 Broken Land Pkwy, Suite 100         <br />
        Columbia, MD 21046         <br />

      </p>
    </div>
  )
}
export default Forms