import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { getAllStaff } from '../../store/practice'
import EditProviderFormModal from './EditProviderFormModal';
import './ProviderList.css'

function ProviderList(){
  const dispatch = useDispatch()
  const allStaff = useSelector(state => state.staff)
  const [loaded, setLoaded] = useState(false)

  let staffArr;

  useEffect(() => {
    dispatch(getAllStaff())
  }, [dispatch])

  useEffect(() => {
    if (staffArr) {
      setLoaded(true)
    }
  }, [staffArr])

  if (allStaff) {
    staffArr = Object.values(allStaff)
  }


  function image(name) {
    if (name === "Goldman") {
      return ("/static/images/MGoldman.jpg")
    } else if (name === "Korff") {
      return ("/static/images/MKorff.jpg")
    } else if (name === "Neuman-Sunshine") {
      return ("/static/images/DNeuman-Sunshine.jpg")
    } else if (name === "Uyehara") {
      return ("/static/images/AUyehara1.jpg")
    } else if (name === "Wettengel") {
      return ("/static/images/CMoughon.jpg")
    } else {
      return ("/static/images/stethoscope.jpeg")
    }
  } 
  if (!staffArr) {
    return null
  }
  function tagline(credential) {
    if (credential === 'M.D.') {
      return "Physician"
    } else if (credential.includes('N.P')) {
      return "Nurse Practitioner"
    }
  }
  function displaytext(display){
    if (display){
      return "Yes"
    } else {
      return "No"
    }
  }
return loaded ? (
  <div className='provider-container'>
    <h3>Active Providers</h3>
    <div className='provider-cards-container'>
    {staffArr.filter(employee => employee.display).map(employee => {
      return (
        <div className='provider-card'>
          <EditProviderFormModal employee={employee}/>
          <h3
            style={{ display: "flex", alignSelf: "bottom", color: "var(--dark-blue)", fontSize: "18px" }}
          >
            Show this Provider? {displaytext(employee.display)}</h3>
          <div className='provider-image-container'>
            <img src={image(employee.lastName)} alt='provider' className='provider-image' />
          </div>
          <div className='provider-card-text'>
            <h3>{tagline(employee.credentials)}</h3>
            <h1 className='provider-names'>
              {employee.firstName} {employee.lastName}, {employee.credentials}
            </h1>
            <p className='provider-biography-text'>{employee.biography}</p>

          </div>
        </div>
      )
    })}
    </div>
    <div className='divider'></div>
    <h3>Inactive Providers</h3>
    <div className='provider-cards-container'>
      {staffArr.filter(employee => !employee.display).map(employee => {
        return (
          <div className='provider-card'>
            <EditProviderFormModal employee={employee} />
            <h3
              style={{ display: "flex", alignSelf: "bottom", color: "var(--dark-blue)", fontSize: "18px" }}
            >
              Show this Provider? {displaytext(employee.display)}</h3>
            <div className='provider-image-container'>
              <img src={image(employee.lastName)} alt='provider' className='provider-image' />
            </div>
            <div className='provider-card-text'>
              <h3>{tagline(employee.credentials)}</h3>
              <h1 className='provider-names'>
                {employee.firstName} {employee.lastName}, {employee.credentials}
              </h1>
              <p className='provider-biography-text'>{employee.biography}</p>
              <h3>Show this Provider? {displaytext(employee.display)}</h3>
            </div>
          </div>
        )
      })}
    </div>
  </div>
) : (
      <h1>Loading...</h1 >
)
}


export default ProviderList