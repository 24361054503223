import React from "react";
// ReactDOM allows developers to access and modify the DOM
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux";

// import { ModalProvider, Modal } from "./context/Modal";
import configureStore from "./store";
import App from "./App";
import { ModalProvider } from "./context/Modal";

import "./index.css"

const store = configureStore()

// // Renders a component
// ReactDOM.render(
//   // Enables re-rendering to find bugs, re-runs Effects an extra time,
//   // checks for usage of deprecated API's in development only
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// )

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ModalProvider>
        <App className='app' />
      </ModalProvider>
    </Provider>
  </React.StrictMode>
)