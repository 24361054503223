import { NavLink } from 'react-router-dom'
import './Footer.css'

function Footer() {

  return (
    <div className="footer-container">
      <div className='footer-sections'>
        <h2 className='footer-headers'>Location</h2>
        <p className='footer-text'>
          <a href="https://goo.gl/maps/42VsgV3zMLbMaFNC9" id="map" target="_blank" rel="noreferrer">
          <i className="fa-solid fa-location-dot"></i>   
          9891 Broken Land Pkwy<br />
          Suite 100 <br />
          Columbia, MD 21046 <br />
          </a>
        </p>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12370.193727091148!2d-76.86232215617488!3d39.18498534851089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7de48ff08c21b%3A0x5071ae473ae17072!2s9891%20Broken%20Land%20Pkwy%20STE%20100%2C%20Columbia%2C%20MD%2021046!5e0!3m2!1sen!2sus!4v1693522906554!5m2!1sen!2sus" style={{maxWidth:"250px", maxHeight:"200px", border:"0", allowfullscreen:"", loading:"lazy", referrerpolicy:"no-referrer-when-downgrade"}}></iframe> */}
      </div>
      {/* <div className='footer-sections'> */}
        {/* <h2 className='footer-headers'>Contact and Hours</h2> */}
        {/* <p className='footer-text' id='hours'>
          <strong>Monday:</strong> 8:30 AM - 7:00 PM <br />
          <strong>Tuesday:</strong> 8:30 AM - 7:00 PM <br />
          <strong>Wednesday:</strong> 8:30 AM - 6:00 PM <br />
          <strong>Thursday:</strong> 8:30 AM - 4:00 PM <br />
          <strong>Friday:</strong> 7:30 AM - 12:00 PM <br />
        </p>
        <p className='footer-text' id='lunch'>
          Closed for Lunch from 12:30-1:00 PM
        </p>
        <p className='footer-text' id='shots'>
          *Allergy shots are given by appointment only <br />
          *Last allergy shot appointment is 40 minutes before closing <br />
        </p> */}
        {/* <a href='/contact'><button className='splash-buttons'>Hours</button></a> */}

        {/* <NavLink to ='/contact' className='footer-headers'>Contact & Hours</NavLink> */}
      {/* </div> */}
      <div className='footer-sections'>
        <h2 className='footer-headers'>Contact & Hours</h2>
        <p className='footer-text'>
          <i className="fa-solid fa-phone fa-xs footer-icons"></i>Phone: <a href="tel:(410)730-6000">(410) 730-6000 </a><br />
          <i className="fa-solid fa-fax fa-xs footer-icons"></i>Fax: (443) 979-7944 <br />
          <NavLink to ='/contact'>Click Here for Hours</NavLink>

        </p>
      </div>
    </div>
  )
}
export default Footer