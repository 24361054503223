/*
This file holds the session reducer. This reducer processes actions dispatched
to the store that relate to the user's session.
*/

/*
These are the types of actions. 
- Constant to prevent typos
- Should be unique to the reducer to prevent action passed to another reducer
*/

const SET_USER = 'session/SET_USER'
const REMOVE_USER = 'session/REMOVE_USER'

/*
Action Creators
- Functions that accept dynamically generated payloads
- Return a JavaScript Object that is considered the action
- These can be dispatched by thunk action creater or within a component
*/
const setUser = (user) => ({
  type: SET_USER,
  payload: user
})

const removeUser = () => ({
  type: REMOVE_USER
})

/*
Thunk Action Creators
- Function that returns a thunk function
- Thunk function is a function that is invoked by thunk middleware and gets passed
  the dispatch and getState store methods
- Asynchronous calls are made here, once complete a regular POJO action may be dispatched
*/
const initialState = { user: null }

// This function checks if the user exists and logs them into the session
export const authenticate = () => async (dispatch) => {
  const response = await fetch('/api/auth/', {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (response.ok) {
    const data = await response.json()
    if (data.errors) {
      return;
    }
    dispatch(setUser(data))
  }
}

// This function logs in a user
export const login = (email, password) => async (dispatch) => {
  const response = await fetch('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    // JSON.stringify turns the JavaScript value into a JSON string
    body: JSON.stringify({
      email,
      password
    })
  })
  // response.json() returns a promise that resolves to a JavaScript Object
  if (response.ok) {
    const data = await response.json()
    dispatch(setUser(data))
    return null
  } else if (response.status < 500) {
    const data = await response.json()
    if (data.errors) {
      return data.errors
    }
  } else {
    return ['An error occurred. Please try again']
  }
}

// Logs the current user out
export const logout = () => async (dispatch) => {
  const response = await fetch('/api/auth/logout', {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (response.ok) {
    dispatch(removeUser())
  }
}

export const signUp = (firstName, lastName, email, password, image) => async (dispatch) => {
  const response = await fetch('/api/auth/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    }, body: JSON.stringify({
      firstName,
      lastName,
      email,
      password,
      image
    })
  })
  if (response.ok) {
    const data = await response.json()
    dispatch(setUser(data))
    return null
  } else if (response.status < 500) {
    const data = await response.json()
    if (data.errors) {
      return data.errors
    }
  } else {
    return ['An error occurred. Please try again.']
  }
}
/*
Session Reducer
- this function checks for the type of action passed to the reducer
*/

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return { user: action.payload }
    case REMOVE_USER:
      return { user: null }
    default:
      return state;
  }
}