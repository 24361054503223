import React, { useState } from 'react';
import { Modal } from '../../../context/Modal';
import EditProviderForm from './EditProviderForm';
import './../ProviderList.css'
function EditProviderFormModal({employee}){
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button onClick={() => setShowModal(true)} className='edit-provider'>Edit Provider</button>
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <EditProviderForm setShowModal={setShowModal} employee={employee} />
        </Modal>
      )}
    </>
  )
}

export default EditProviderFormModal;
