import Message from "../message/Message"
import Navbar from "../navbar/Navbar"
import './Header.css'

function Header() {

  return (
    <div className="header-container">
      <Navbar />
      <Message />
    </div>
  )

}
export default Header