import './Splashpage.css'

// This is the component that renders the entire Splashpage
function Splashpage() {

  // Below is the JSX 
  return (
    <div className='splash-container'>
      <div className='splash-bg'>
      </div>
      <div className='splash-overlay-container'>
        <h1 className='splash-overlay'>Welcome to Allergy & Asthma of Central Maryland</h1>
        <h3 className='splash-overlay-sub'>Providing compassionate and comprehensive care since 1991</h3>
      </div>
      <div className='splash-cards-container'>
        <div className='splash-cards'>
          <div className='splash-icon-container'>
            <i className="fa-solid fa-user-doctor fa-2xl"></i>
          </div>
          <h3 className='splash-headers'>Our Practice</h3>
          <p className="splash-cards-content">
            Our board-certified allergists and nurse practitioners see pediatric and adult patients.
            {/* Learn more about <br /> our providers! */}
          </p>
          <a href='/ourpractice'><button className='splash-buttons'>Learn More</button></a>

        </div>
        <div className='splash-cards'>
          <div className='splash-icon-container'>
            <i class="fa-solid fa-stethoscope fa-2xl"></i>
          </div>
          <h3 className='splash-headers'>Services</h3>
          <p className="splash-cards-content">
            We offer various treatment options including allergy shots, allergy drops, patch testing, and oral immunotherapy (OIT).
            {/* We treat patients with allergic rhinitis, asthma, food allergy, eczema, bee sting allergy, drug allergy, allergic contact dermatitis, and offer oral immunotherapy (OIT) to various food allergens. */}
          </p>
          <a href='/services'><button className='splash-buttons'>Learn More</button></a>

        </div>
        <div className='splash-cards'>
          <div className='splash-icon-container'>
            <i class="fa-solid fa-clock fa-2xl"></i>
          </div>
          <h3 className='splash-headers'>Contact & Hours</h3>
          <p className="splash-cards-content">
            Obtain our contact details, operating hours, and office entrance information. 
          </p>
          <a href='/contact'><button className='splash-buttons'>Learn More</button></a>

        </div>
      </div>
    </div>
  )
}

export default Splashpage