import './Services.css'
import { useState } from 'react'

function Services() {
  const [service, setService] = useState(1)


  const serviceDetails = {
    1: {
      title: "Allergic Rhinitis",
      description: "Allergic rhinitis, also referred to as hay fever or environmental allergies, is the immune system’s response to airborne allergens.  Common symptoms include runny nose, sneezing, nasal congestion, itchy eyes.  Medications, like antihistamines and nasal steroid sprays, are available over-the-counter.  If medications are not effective enough, we offer allergy shots and allergy drops under the tongue."
    },
    2: {
      title: "Asthma",
      description: "Asthma may present in children and adult patients with a range of symptoms, including coughing, wheezing, shortness of breath, and or chest tightness.  We are experienced in evaluating and managing patients with asthma symptoms, including management with biologic medications. "
    },
    3: {
      title: "Food Allergies",
      description: "Different adverse reactions may occur after eating a food.  The most commonly known adverse reaction involves the immune system producing allergic antibodies, IgE, which react by releasing different mediators throughout the body when a food allergen is ingested.  As a result, an allergic person may develop hives, itching, swelling, vomiting, breathing difficulty, anaphylaxis, which may be life-threatening."
    },
    4: {
      title: "Atopic Dermatitis",
      description: "Atopic dermatitis, also referred to as eczema, is a chronic skin condition characterized by itchy, inflamed, dry skin due to the inability to retain mositure.  Both children and adults may develop atopic dermatitis.  Infants with severe atopic dermatitis are at higher risk of developing other allergic conditions, like food allergies.  We are experienced in evaluating and managing infants and children with severe atopic dermatitis.  We also offer biologic medications for appropriate patients."
    },
    5: {
      title: "Chronic Urticaria",
      description: "Urticaria, also known as hives, may occur in patients that persist for weeks, months, or even years.  Many cases are idiopathic, meaning there is no identifiable cause, after a medical work-up is completed to rule out possible primary causes.  We are experienced in assessing and managing patients with chronic urticaria.  We also offer biologic medication in appropriate patients."
    },
    6: {
      title: "Contact Dermatitis",
      description: "Contact dermatitis is a different skin condition that involves a delayed reaction of the skin as a result of a substance in direct contact with the skin. The rash may be red, itchy and uncomfortable.  Common triggers are found in cosmetics, hair dye, fake jewelry, and plants."
    },
    7: {
      title: "Venom Allergy",
      description: "Allergic reactions may occur from a sting from various bee venom.  Reactions may be mild, like localized redness and swelling.  Severe reactions, or anaphylaxis, may include symptoms of widespread swelling, breathing difficulty, and possibly a drop in blood pressure.  Immediate treatment with epinephrine is necessary in anaphylaxis.  We test for various venom, including honeybee, wasp, white-faced hornet, yellow-faced hornet, and yellow jacket. If there is a history of anaphylaxis and the venom is identified on testing, then treatment with venom immunotherapy is recommended. We are experienced in both testing and treatment of venoms."
    },
    8: {
      title: "Drug Allergies",
      description: "Different adverse reactions may occur with ingestion of medications.  Symptoms of hives, itching, swelling may occur due to various medications, like penicillin or non-steroidal anti-inflammatory drugs (NSAIDs).  The reaction may or may not be indicative of an anaphylactic allergy.  We are experienced in assessing and managing medication adverse reactions."
    }
  }

  function isActive(num) {
    return (service === num) ? 'active' : 'inactive'
  }


  return (
    <div className="services-container">
      {/* Header */}
        {/* <h1>Our Services</h1> */}

      {/* Content */}
      <div className='services-content-container'>
        <div className='services-tabs-container'>
          <p className='services-tabs'
            onClick={(e) => setService(1)}
            id={isActive(1)}
          >
            <i className="fa-solid fa-syringe fa-large services-icons"></i>
            Allergic Rhinitis
          </p>
          <p className='services-tabs'
            id={isActive(2)}
            onClick={(e) => setService(2)}>
            <i className="fa-solid fa-lungs fa-large services-icons"></i>
            Asthma
          </p>
          <p className='services-tabs'
            id={isActive(3)}
            onClick={(e) => setService(3)}>
            <i className="fa-solid fa-utensils fa-large services-icons"></i>
            Food Allergies
          </p>
          <p className='services-tabs'
            id={isActive(4)}
            onClick={(e) => setService(4)}>
            <i className="fa-solid fa-droplet-slash fa-large services-icons"></i>
            Atopic Dermatitis
          </p>
          <p className='services-tabs'
            id={isActive(5)}
            onClick={(e) => setService(5)}>
            <i className="fa-solid fa-person-dots-from-line fa-large services-icons"></i>
            Chronic Urticaria
          </p>
          <p className='services-tabs'
            id={isActive(6)}
            onClick={(e) => setService(6)}>
            <i className="fa-solid fa-hand-dots fa-large services-icons"></i>
            Contact Dermatitis
          </p>
          <p className='services-tabs'
            id={isActive(7)}
            onClick={(e) => setService(7)}>
            <i className="fa-brands fa-forumbee fa-large services-icons"></i>
            Venom Allergy
          </p>
          <p className='services-tabs'
            id={isActive(8)}
            onClick={(e) => setService(8)}>
            <i className="fa-solid fa-pills fa-large services-icons"></i>
            Drug Allergy
          </p>


        </div>
        <div className='services-detail-container'>
          <h1>
            {serviceDetails[service].title}
          </h1>
          <p>
            {serviceDetails[service].description}
          </p>
          <div className='services-subservices'>
            <div className='services-allergic-rhinitis' id={isActive(1)}>
              <div className='subservice-container'>
                <h2>Subcutaneous Immunotherapy (SCIT)</h2>
                <p>
                  Subcutaneous immunotherapy, also known as allergy shots, is a treatment option when patients are seeking relief of their allergic rhinitis symptoms that are not sufficiently controlled with medications alone.  Allergy shots may also be an option for patients who experience adverse side effects from medications or for patients seeking a form of treatment that may provide long-term benefit.
                </p>
              </div>
              <div className='subservice-container'>
                <h2>Sublingual Immunotherapy (SLIT)</h2>
                <p>
                  Sublingual immunotherapy, also known as allergy drops, is a treatment option similar to allergy shots, but are administered as drops under the tongue at home rather than as shots in the office.
                </p>
              </div>
            </div>
            <div className='services-asthma' id={isActive(2)}>
              <div className='subservice-container'>
                <h2>Spirometry</h2>
                <p>
                  Spirometry is a pulmonary function or breathing test used to assess lung function.  The test measures how much air a patient can forcefully inhale and exhale.  It also measures how fast the air moves.  We offer spirometry as a tool to aid in the diagnosis and management of asthma.
                </p>
              </div>
              <div className='subservice-container'>
                <h2>FeNO</h2>
                <p>
                  Fractional exhaled nitric oxide, or FeNO, is a breathing test that assesses inflammation in the lungs.  The test measures how much nitric oxide is in the air of a patient when slowly exhaled.  We offer FeNO as another tool to aid in the diagnosis and management of allergic and eosinophilic asthma.
                </p>
              </div>
            </div>
            <div className='services-contact' id={isActive(6)}>
              <div className='subservice-container'>
                <h2>Patch Testing</h2>
                <p>
                  Patch testing is used to identify a substance or substances that may cause allergic contact dermatitis in a patient. Patches are placed on the skin and left on for 48 hours before removal.  An initial reading and assessment of the results are completed at removal with a final reading 2-5 days later.  We offer patch testing to the top 80 contact allergens, including common metals.
                </p>
              </div>
            </div>
            <div className='services-drug' id={isActive(8)}>
              <div className='subservice-container'>
                <h2>Penicillin Testing</h2>
                <p>
                  Penicillin allergy is commonly labeled in patients who have had a history of rash after taking penicillin-based medication, like amoxicillin.  We offer penicillin testing and penicillin challenge to better assess the risk of an anaphylactic allergic reaction.
                </p>
              </div>
            </div>
            <div className='services-food' id={isActive(3)}>
              <div className='subservice-container'>
                <h2>Oral Immunotherapy</h2>
                <p>
                  Standard of care treatment for food allergies is avoidance of the food allergen and keeping an epinephrine injector available and using it for a systemic reaction.  We offer oral immunotherapy (OIT) as an optional treatment to desensitize patients to specific food allergens.  It is not a cure for food allergies.  OIT involves eating small, increasing amounts of the food allergen gradually over time to reach a maintenance amount.  With time, the immune system is less sensitive to the food allergen so that if the food is accidentally ingested, the severity of a reaction is less, or possibly absent.  The maintenance dose must be kept in the diet daily to maintain tolerance of the food allergen.  Our practice offers OIT for peanut, selective tree nuts, milk, egg, wheat, and sesame.
                </p>
              </div>
              <div className='subservice-container'>
                <h2>Early Food Allergen Introduction</h2>
                <p>
                  There is strong evidence to support early introduction of peanut in infants to prevent the potential development of peanut allergy.  We are experienced in treating children from early infancy to introduce high-risk food allergens.
                </p>
              </div>
              <div className='subservice-container'>
                <h2>Food Challenges</h2>
                <p>
                  An oral food challenge is a procedure by which a patient is observed in the office while eating up to a serving size of a food allergen as a “test” to determine tolerance.  We are experienced in treating allergic reactions, including anaphylaxis, in infants through adults.
                </p>
              </div>
              <div className='subservice-container'>
                <h2>Food Protein Induced Enterocolitis Syndrome (FPIES)</h2>
                <p>
                  Food Protein Induced Enterocolitis Syndrome (FPIES) is another type of adverse reaction to a food that is different from the type mediated by the IgE antibody.  FPIES is a delayed-type of reaction that primarily involves repetitive vomiting from the culprit food.  The most common foods that trigger this type of reaction are oat, rice, milk, and soy in infants.  FPIES is typically outgrown by around age 3-4 years.  There is no current treatment for FPIES.  The culprit food must be avoided.  Anti-nausea medication may be prescribed for mild reactions.  For severe reactions, patients must be evaluated in the Emergency Room due to the risk of dehydration and hypovolemic shock. We are experienced in evaluating and managing patients who have FPIES.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Services