import './Telemedicine.css'

function Telemedicine() {

  return (
    <div className='telemedicine-container'>
      <div className='telemedicine-text-container'>
        <h1 id='tele-form-header'>Telemedicine Visits</h1>
        <h1>What are telemedicine visits?</h1>
        <p>Telemedicine appointments are video visits that allow your provider to see and talk to you about your medical concerns. Patients are required to be visible in the video (including children if the patient is a child) and are also required to be in the state of Maryland during the visit.</p>
        <h1>Scheduling telemedicine appointments:</h1>
        <p>Patients may schedule a telemedicine appointment by calling our office at <a href="tel:(410)730-6000" className='telemedicine-phone-text'>(410) 730-6000</a>.</p>
        <h1>What to expect prior to your appointment:</h1>
        <p>Prior to your appointment, you will receive an email confirming the date and time of your appointment.  The email also includes links to electronic documents to sign prior to your visit.
          <br /><br /><strong>New Patients</strong> -- sign the <strong>HIPAA
          </strong> and <strong>Release of Benefits
            Release of Benefits
          </strong> documents and complete the <strong>Intake Form</strong>.
          <br />
          <br /><strong>Follow Up Patients</strong> -- sign the <strong>HIPAA
          </strong> and <strong>
          </strong> documents if not been seen in the last 12 months and complete the <strong>Intake Form</strong> if there have been any changes, such as a different primary care provider, pharmacy, and/or a change in social history information (ex. new pets).
        </p>
        <h1>What to expect on appointment day:</h1>
        <p>
          On the day of your appointment, you will receive a phone call from a Medical Assistant to obtain pre-appointment information.  You will receive the link for your visit at that time.
        </p>
      </div>
    </div>
  )
}
export default Telemedicine