// Store holds the state tree of the application. Only make 1 per application
// Reducers return the next state tree. Inputs are current state and an action
// Middleware wraps store's dispatch. Supports async actions in addition to normal actions
// compose functions from right to left

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// Thunk is a middleware for Redux that allows writing functions with logic inside that can interact with a 
// Redux store's dispatch and getState methods
import thunk from 'redux-thunk';
import session from './session';
import staffReducer from './practice';
import messageReducer from './message';

const rootReducer = combineReducers({
  session,
  // additional reducers will be added here in the future as their reducers are coded
  staff: staffReducer,
  message: messageReducer
})

let enhancer;

// The following conditional statement sets the middleware dependent on 
// production or development. Development adds a redux logger

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(thunk)
} else {
  const logger = require('redux-logger').default;
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // logger must be the last middleware chain otherwise it will log thunk and promises, not actual actions
  enhancer = composeEnhancers(applyMiddleware(thunk, logger))
}


// Creates the store
const configureStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, enhancer)
}

export default configureStore;