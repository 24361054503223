import { useSelector } from 'react-redux';
import { logout } from '../../store/session';
import './AdminBanner.css'
import { useDispatch } from 'react-redux';

function AdminBanner() {
  const currentUser = useSelector(state => state.session.user);
  const dispatch = useDispatch()
  const onLogout = async (e) => {
    await dispatch(logout())
  }


  if (!currentUser) return null


  return (
    <div className='adminbanner'>
      <p style={{margin:'0', paddingBottom: '20px'}}>
        Currently logged in as {currentUser.email}
      </p>
      <button onClick={onLogout} className='logoutbutton'>Logout</button>
    </div>
  )
}
export default AdminBanner