import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { createAMessage, getAMessage, getAllMessage } from '../../store/message'
import './CreateMessageForm.css'
import { useSelector } from 'react-redux';

const CreateMessageForm = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([])
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [message, setMessage] = useState('')
  const [display, setDisplay] = useState('Yes')
  const [loaded, setLoaded] = useState(false)
  const allPrevious = useSelector(state => state.message)
  let previous;

  useEffect(() => {
    dispatch(getAllMessage)
  }, [dispatch])

  useEffect(() => {
    setLoaded(true)
  }, [previous])

  if (allPrevious) {
    previous = Object.values(allPrevious)
  }
  const createMessage = async (e) => {
    e.preventDefault()
    setHasSubmitted(true)

    let payload = { message, display }
    let data = await dispatch(createAMessage(payload));
    if (Array.isArray(data)) {
      setErrors(data)
    } else {
      await dispatch(getAMessage(data.id))
      setMessage('')
      setDisplay('')
      setErrors('')
      setHasSubmitted(false)
    }
  }

  function charRemaining(max, input) {
    return Number(max) - Number(input.length)
  }

  return loaded && previous ? (
    <div className='createmessage-container' >
      {hasSubmitted && errors.length > 0 && (<div className='error-container'>
        {errors.map((error, ind) => (
          <div key={ind} className='error-text'>{error.split(":")[1]}</div>
        ))}
      </div>)}

      <form onSubmit={createMessage} >
        <p className='createmessage-header'>Create New Message</p>
        <p className='createmessage-info'>To include a line break type "newline" in your message</p>
        <div >
          <textarea
            maxLength={1000}
            type='text'
            name='message'
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder="Message goes here"
            className='createmessage-input'
          >
          </textarea>
          <p className='createmessage-info' >{charRemaining(1000, message)} characters remain</p>
        </div>
        <button type='submit' className='createmessage-button'>Submit</button>

      </form>
      <div className='prevmessage-container'>
        <h3 className='createmessage-header'>
          Previous Messages
        </h3>
        <div className='prevmessage-inner-container'>

          {previous.slice(-5).map(message => {
            return (
              <div key={message.id}>
                <p>{message.id}: {message.message}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  ) : (
    <h1>Loading...</h1>
  )
}
export default CreateMessageForm